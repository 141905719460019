<script setup lang="ts">
import Banking from '~/components/banking/banking.vue';
import { useAuthStore } from '~/stores/auth.store';
import { useSiteStore } from '~/stores/site.store';

const authStore = useAuthStore();
const site = useSiteStore();

onBeforeMount(() => {
  if (
    authStore.getComplianceStatus < 1 &&
    site.getFeatureFlags.get('myaccount.enablecompliance')
  ) {
    authStore.setAccountModalPage('document-verification');
  } else {
    checkLimitStatus();
  }
});

function checkLimitStatus() {
  // TODO: Find correct property for blocking deposit
  if (authStore?.getRestrictionPermissions?.allowedWager === false) {
    setTimeout(() => {
      authStore.toggleAccountModal(false);
    }, 250);
    site.activateModal('accountRestricted');
  }
}
</script>
<template>
  <div>
    <LazyUserAccountSettingsHeader
      :title="`${$t('deposit')} (${authStore.currentUser.primaryMobileNumber})`"
      show-balance-mobile
      return-route="teller"
    />
    <div class="px-3 hidden md:block">
      <UserBalances
        class="w-full"
        display-balance-toggle
        display-cash-balance
        display-bonus-balance
        display-total-balance
        stretch
        account-options-modal-style
        single-line
      />
    </div>
    <Banking type="Deposits" />
  </div>
</template>
